import { svgIconClasses } from '@mui/material';
import { tss } from 'tss-react/mui';

export const useStyles = tss.withName('LayoutHeaderDrawer').create(({ theme }) => ({
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.gutter.lg,
    padding: theme.gutter.sm
  },
  drawerPaper: {
    borderTop: 0,
    minWidth: theme.typography.pxToRem(300)
  },
  drawerListItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  drawerListItemIcon: {
    minWidth: 0
  },
  drawerListItemArrowDown: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short
    }),
    willChange: 'transform'
  },
  drawerListItemArrowDownOpen: {
    transform: 'rotate(180deg)'
  },
  hamburger: {
    [`& .${svgIconClasses.root}`]: {
      width: theme.typography.pxToRem(30),
      height: theme.typography.pxToRem(30)
    },
    [theme.containerQueries.up(theme.breakpoints.values.desktop)]: {
      display: 'none'
    }
  }
}));
