import { tss } from 'tss-react/mui';

export const useStyles = tss.withName('HeaderNavMenuComponentNavMenuItemBlock').create(({ theme }) => ({
  subMenuItemActive: {
    color: theme.palette.text.secondary
  },
  menuPaper: {
    maxWidth: theme.typography.pxToRem(150),
    maxHeight: 'fit-content',
    padding: theme.gutter.xs
  },
  menuItemText: {
    whiteSpace: 'normal'
  },
  menuItem: {
    minHeight: theme.spacing(1)
  }
}));
