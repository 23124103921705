import { useMediaQuery, useTheme } from '@mui/material';

export const useMediaHooks = () => {
  const { breakpoints } = useTheme();

  const isDesktop = useMediaQuery(breakpoints.up('desktop'));
  const isTablet = useMediaQuery(breakpoints.between('tablet', 'desktop'));
  const isMobile = useMediaQuery(breakpoints.down('tablet'));

  return { isMobile, isTablet, isDesktop };
};
