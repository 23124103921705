import { tss } from 'tss-react/mui';

export const useStyles = tss.withName('LayoutHeaderNavMenuMenuButton').create(({ theme }) => ({
  arrowDropDown: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short
    }),
    willChange: 'transform'
  },
  arrowDropDownOpen: {
    transform: 'rotate(180deg)'
  },
  active: {
    color: theme.palette.warning.main
  },
  activeOpen: {
    '&&': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main,
      textUnderlineOffset: '4px'
    }
  },
  menuButton: {
    zIndex: theme.zIndex.modal + 1000 + 1,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'none'
  }
}));
