import { KeyboardArrowDown } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuButtonProps } from '@althera/website/layout/header/types';
import { useStyles } from './index.style';

export const MenuButton = (props: MenuButtonProps) => {
  const { anchorEl, isMenuOpen, pathname, to, label, onMenuOpen, hasSubLinks } = props;
  const { classes, cx } = useStyles();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (pathname === to) {
      onMenuOpen();
      return;
    }
    navigate(to);
  }, [navigate, onMenuOpen, pathname, to]);

  const handleMenuOpen = useCallback(
    (e: MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      e.preventDefault();
      onMenuOpen();
    },
    [onMenuOpen]
  );

  return (
    <Button
      type="button"
      ref={anchorEl}
      endIcon={hasSubLinks && <KeyboardArrowDown onClick={handleMenuOpen} className={cx(classes.arrowDropDown, { [classes.arrowDropDownOpen]: isMenuOpen })} />}
      className={cx({ [classes.active]: pathname === to, [classes.activeOpen]: isMenuOpen }, classes.menuButton)}
      variant="text"
      color={pathname === to ? 'secondary' : 'inherit'}
      onClick={handleClick}>
      <Typography variant="subheadingSmall" fontWeight="fontWeightRegular">
        {label}
      </Typography>
    </Button>
  );
};
