import { Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuLink } from '@althera/website/layout/types';
import { NavMenuItem } from './blocks/navManuItem';
import { useStyles } from './index.style';

interface NavMenuProps {
  links: MenuLink[];
}

export const NavMenu = (props: NavMenuProps) => {
  const { links } = props;
  const { classes } = useStyles();
  const { pathname, hash } = useLocation();
  const [menuOpen, setMenuOpen] = useState('');

  const handleMenuOpen = useCallback((label: string) => {
    return setMenuOpen(prev => (prev === label ? '' : label));
  }, []);

  return (
    <Stack className={classes.root} component="nav" direction="row" gap={0.5}>
      {links.map(({ to, label, subLinks }) => {
        return <NavMenuItem key={label} pathname={pathname} hash={hash} subMenuOpen={menuOpen} onMenuOpen={handleMenuOpen} to={to} label={label} subLinks={subLinks} />;
      })}
    </Stack>
  );
};
