import { RouteObject } from 'react-router-dom';

const Home: RouteObject = {
  id: 'home',
  path: '/',
  async lazy() {
    const { Home } = await import('@althera/website/scenes/home');
    return { Component: Home };
  }
};

const AboutUs: RouteObject = {
  id: 'about-us',
  path: '/about-us',
  async lazy() {
    const { AboutUs } = await import('@althera/website/scenes/about-us');
    return { Component: AboutUs };
  }
};

const Culture: RouteObject = {
  id: 'culture',
  path: '/culture',
  async lazy() {
    const { Culture } = await import('@althera/website/scenes/culture');
    return { Component: Culture };
  }
};

const Team: RouteObject = {
  id: 'team',
  path: '/team',
  async lazy() {
    const { Team } = await import('@althera/website/scenes/team');
    return { Component: Team };
  }
};

const Products: RouteObject = {
  id: 'products',
  path: '/products',
  async lazy() {
    const { Products } = await import('@althera/website/scenes/products');
    return { Component: Products };
  }
};

const Careers: RouteObject = {
  id: 'careers',
  path: '/careers',
  async lazy() {
    const { Careers } = await import('@althera/website/scenes/careers');
    return { Component: Careers };
  }
};

const ContactUs: RouteObject = {
  id: 'contact-us',
  path: '/contact-us',
  async lazy() {
    const { ContactUs } = await import('@althera/website/scenes/contact-us');
    return { Component: ContactUs };
  }
};

const StyleGuide: RouteObject = {
  id: 'style-guide',
  path: '/style-guide',
  async lazy() {
    const { Styleguide } = await import('@althera/website/scenes/styleguide');
    return { Component: Styleguide };
  }
};

export const Routes = [Home, AboutUs, Culture, Team, Products, Careers, ContactUs, StyleGuide];
