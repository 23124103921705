import { QueryContextProvider } from '@althera/website/contexts/query';
import { ContextsRouter } from '@althera/website/contexts/router';
import { ThemeContextProvider } from '@althera/website/contexts/theme/index';
import { createContextProviders } from '@althera/website/helpers/createContextProviders';

const ContextProviders = createContextProviders([QueryContextProvider, ThemeContextProvider]);

export const Contexts = () => (
  <ContextProviders>
    <ContextsRouter.Provider />
  </ContextProviders>
);
