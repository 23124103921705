import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Contexts } from '@althera/website/contexts';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <Contexts />
  </StrictMode>
);
