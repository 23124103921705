import { Close, MenuOutlined } from '@mui/icons-material';
import { ClickAwayListener, IconButton, Drawer as MuiDrawer, Paper, Popper, Stack } from '@mui/material';
import { useCallback, useId, useRef, useState } from 'react';
import { Logo } from '@althera/website/components/logo';
import { useMediaHooks } from '@althera/website/hooks/useMediaHooks';
import { MenuLink } from '@althera/website/layout/types';
import { useStyles } from './drawer.style';
import { LinksList } from './linksList';

interface DrawerProps {
  links: MenuLink[];
}

export const Drawer = (props: DrawerProps) => {
  const { links } = props;
  const { classes } = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const iconRef = useRef<HTMLButtonElement>(null);
  const { isMobile, isTablet } = useMediaHooks();
  const hamburgerId = useId();

  const handleMenuOpen = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsDrawerOpen(prev => !prev);
  }, []);

  const handleMenuClose = useCallback(() => setIsDrawerOpen(false), []);

  return (
    <>
      <IconButton ref={iconRef} className={classes.hamburger} edge={isTablet ? 'end' : 'start'} onClick={handleMenuOpen}>
        <MenuOutlined id={hamburgerId} />
      </IconButton>
      <MuiDrawer classes={{ paper: classes.drawerPaper }} open={isDrawerOpen && isMobile} onClose={handleMenuClose}>
        <Stack className={classes.drawerHeader} direction="row">
          <Logo />
          <IconButton edge="end" onClick={handleMenuClose}>
            <Close />
          </IconButton>
        </Stack>
        <LinksList links={links} onNavigate={handleMenuClose} />
      </MuiDrawer>
      <ClickAwayListener
        onClickAway={(e: MouseEvent | TouchEvent) => {
          const target = e.target as HTMLElement;
          if (target.id && target.id === hamburgerId) {
            return;
          }
          handleMenuClose();
        }}>
        <Popper disablePortal open={isDrawerOpen && isTablet} anchorEl={iconRef.current?.closest('header')} placement="bottom-end">
          <Paper>
            <LinksList links={links} isTablet={true} onNavigate={handleMenuClose} />
          </Paper>
        </Popper>
      </ClickAwayListener>
    </>
  );
};
