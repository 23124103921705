import { AppBar, Stack } from '@mui/material';
import { GridSpacer } from '@althera/website/components/gridSpacer';
import { Logo } from '@althera/website/components/logo';
import { links } from '@althera/website/layout/helper';
import { Drawer } from './components/drawer';
import { NavMenu } from './components/navMenu';
import { useStyles } from './index.style';

export const Header = () => {
  const { classes } = useStyles();
  return (
    <AppBar component="header" position="sticky" variant="outlined" color="inherit">
      <GridSpacer rowOffset={{ desktop: 1, tablet: 1, mobile: 1 }}>
        <Stack className={classes.appbar}>
          <Logo />
          <NavMenu links={links} />
          <Drawer links={links} />
        </Stack>
      </GridSpacer>
      <GridSpacer rowOffset={{ desktop: 1, tablet: 1, mobile: 1 }} />
    </AppBar>
  );
};
