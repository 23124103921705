import { Box } from '@mui/material';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useStyles } from './index.style';

export const Main = () => {
  const { classes } = useStyles();
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      // Wait for next frame + small delay
      // it makes sure that all the elements of the page are loaded
      requestAnimationFrame(() => {
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      });
    }
  }, [hash]);

  return (
    <Box className={classes.root} component="main">
      <Outlet />
    </Box>
  );
};
