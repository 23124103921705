import { useNavigate } from 'react-router-dom';
import logo from '@althera/website/assets/logo.svg';
import { useStyles } from './index.style';

export const Logo = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  return (
    <img
      className={classes.root}
      src={logo}
      alt="Althera"
      onClick={() => {
        navigate('/');
      }}
    />
  );
};
