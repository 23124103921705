import { KeyboardArrowDown } from '@mui/icons-material';
import { Collapse, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Fragment, MouseEvent, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MenuLink } from '@althera/website/layout//types';
import { useSubMenuOpen } from '@althera/website/layout/header//hooks';
import { useStyles } from './linksList.style';

interface LinksListProps {
  links: MenuLink[];
  onNavigate: () => void;
  isTablet?: boolean;
}

export const LinksList = (props: LinksListProps) => {
  const { links, isTablet, onNavigate } = props;
  const { pathname, hash } = useLocation();
  const { classes, cx } = useStyles();
  const { subMenuOpen, handleSubMenuOpen } = useSubMenuOpen();

  const handleMenuOpen = useCallback(
    (e: MouseEvent<SVGSVGElement>, label: string) => {
      e.stopPropagation();
      e.preventDefault();
      handleSubMenuOpen(label);
    },
    [handleSubMenuOpen]
  );

  return (
    <List className={classes.root}>
      {links.map(({ to, label, subLinks }) => {
        const isOpen = subMenuOpen.has(label);
        return (
          <Fragment key={label}>
            <ListItem
              className={cx(classes.mainListItem, {
                [classes.mainListItemOpen]: isOpen,
                [classes.listItemActive]: pathname === to,
                [classes.isTablet]: isTablet
              })}
              component={Link}
              to={to ?? '/'}
              key={label}
              onClick={
                subLinks
                  ? () => {
                      handleSubMenuOpen(label);
                      onNavigate();
                    }
                  : onNavigate
              }>
              <ListItemText
                className={cx({
                  [classes.listItemText]: isTablet,
                  [classes.mainListItemTextOpen]: isOpen,
                  [classes.listItemTextIndented]: isTablet && !subLinks
                })}>
                <Typography variant="subheadingBig">{label}</Typography>
              </ListItemText>
              {subLinks && subLinks.length > 0 && (
                <ListItemIcon className={classes.listItemIcon}>
                  <KeyboardArrowDown className={cx(classes.listItemArrowDown, { [classes.listItemArrowDownOpen]: isOpen })} onClick={e => handleMenuOpen(e, label)} />
                </ListItemIcon>
              )}
            </ListItem>
            {subLinks && subLinks.length > 0 && (
              <Collapse in={isOpen} unmountOnExit>
                {subLinks.map(subLink => (
                  <ListItem
                    className={cx({ [classes.listItemActive]: `#${subLink.to}` === hash })}
                    component={Link}
                    to={`${to}#${subLink.to}`}
                    key={subLink.label}
                    onClick={onNavigate}>
                    <ListItemText
                      className={cx({
                        [classes.listItemText]: isTablet,
                        [classes.listItemTextIndented]: isTablet
                      })}>
                      <Typography
                        variant="subheadingSmall"
                        className={cx({
                          [classes.listItemTextTypography]: isTablet
                        })}>
                        {subLink.label}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </Collapse>
            )}
          </Fragment>
        );
      })}
    </List>
  );
};
