import { Box } from '@mui/material';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Routes } from '@althera/website/contexts/router/helpers';
import { Layout } from '@althera/website/layout';
import { NotFound } from '@althera/website/scenes/not-found';
import { ErrorBoundary } from './components/errorBoundary';

const router = createBrowserRouter([
  {
    id: 'root',
    element: <Layout />,
    children: [
      {
        errorElement: <ErrorBoundary />,
        children: Routes
      },
      {
        id: 'page-not-found',
        path: '*',
        element: <NotFound />
      }
    ]
  }
]);

const Provider = () => {
  return <RouterProvider router={router} fallbackElement={<Box />} />;
};

export const ContextsRouter = {
  Provider
};
