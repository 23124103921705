import { tss } from 'tss-react/mui';
import heart from '@althera/website/assets/svgs/not-found/heart.svg';

export const useStyles = tss.withName('NotFoundScene').create(({ theme }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${heart})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    gap: theme.gutter.md,
    margin: theme.spacing(theme.gutter.xxl, theme.gutter.md)
  },
  text: {
    textAlign: 'center',
    [theme.containerQueries.down(theme.breakpoints.values.tablet)]: {
      textAlign: 'left'
    }
  }
}));
