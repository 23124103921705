import { tss } from 'tss-react/mui';

export const useStyles = tss.withName('LayoutHeaderLinksList').create(({ theme }) => ({
  root: {
    paddingBottom: theme.gutter.xl
  },
  mainListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginRight: theme.gutter.xl
  },
  mainListItemOpen: {
    backgroundColor: theme.palette.info.main
  },
  mainListItemTextOpen: {
    color: theme.palette.info.contrastText
  },
  listItemText: {
    paddingRight: theme.gutter.xl,
    textAlign: 'right'
  },
  listItemIcon: {
    minWidth: 0
  },
  listItemArrowDown: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short
    }),
    willChange: 'transform'
  },
  listItemArrowDownOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.info.contrastText
  },
  listItemActive: {
    color: theme.palette.warning.main
  },
  listItemTextIndented: {
    paddingLeft: theme.gutter.md
  },
  listItemTextTypography: {
    maxWidth: theme.typography.pxToRem(150),
    display: 'block'
  },
  isTablet: {
    flexDirection: 'row-reverse'
  },
  hamburger: {
    display: 'none',
    [theme.containerQueries.down(theme.breakpoints.values.desktop)]: {
      display: 'block'
    },
    '& svg': {
      width: '48px',
      height: '48px'
    }
  }
}));
