import { AppBar, Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { GridSpacer } from '@althera/website/components/gridSpacer';
import { Logo } from '@althera/website/components/logo';
import { useMediaHooks } from '@althera/website/hooks/useMediaHooks';
import { links } from '@althera/website/layout/helper';
import { useStyles } from './index.style';

export const Footer = () => {
  const { classes, cx } = useStyles();
  const { isMobile } = useMediaHooks();
  return (
    <AppBar className={classes.root} component="footer" position="static" variant="outlined" color="transparent">
      <GridSpacer rowOffset={{ desktop: 3, tablet: 1.5, mobile: 1.25 }}>
        <Box className={classes.appbar}>
          <Box className={classes.logo}>
            <Logo />
          </Box>
          <Box className={classes.menus}>
            {links
              .filter(link => link.label !== 'Home')
              .map(link => (
                <List key={link.to} className={classes.menu}>
                  <ListItem className={classes.mainMenuItem}>
                    <ListItemText>
                      <Link className={classes.link} to={link.to}>
                        <Typography variant={isMobile ? 'subheadingSmall' : 'subheadingMedium'}>{link.label}</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  {!isMobile &&
                    link.subLinks?.map(subLink => (
                      <ListItem key={subLink.to}>
                        <ListItemText>
                          <Link className={classes.link} to={`${link.to}#${subLink.to}`}>
                            <Typography className={classes.subMenuItem} variant="subheadingSmall">
                              {subLink.label}
                            </Typography>
                          </Link>
                        </ListItemText>
                      </ListItem>
                    ))}
                </List>
              ))}
          </Box>
          <Box className={classes.copyright}>
            <Typography className={classes.copyrightText} variant="subheadingSmall" color="primary.contrastText">
              ©{new Date().getFullYear()} Althera. All rights reserved.
            </Typography>
            <Link className={cx(classes.copyrightPolicy, classes.link)} to="/privacy-policy">
              <Typography variant="subheadingSmall">Privacy Policy</Typography>
            </Link>
            <Link className={cx(classes.copyrightTerms, classes.link)} to="/terms-and-conditions">
              <Typography variant="subheadingSmall">Terms and Conditions</Typography>
            </Link>
          </Box>
        </Box>
      </GridSpacer>
      <GridSpacer rowOffset={{ desktop: 3, tablet: 3, mobile: 3 }} />
    </AppBar>
  );
};
