import { PropsWithChildren } from 'react';

/**
 * Creates a function that wraps the given providers around the children
 *
 * @param providers The providers to wrap the children in
 * @returns A function that takes the children as an argument and returns the wrapped children
 * @example
 * const ContextProviders = createContextProviders([Provider1, Provider2, Provider3]);
 * const App = () => ( <ContextProviders> <App /> </ContextProviders> );
 * // Equivalent to:
 * const App = () => (  <Provider1> <Provider2> <Provider3> <App /> </Provider3> </Provider2> </Provider1> );
 */
export const createContextProviders =
  (providers: React.FunctionComponent<PropsWithChildren>[]) =>
  ({ children }: { children: JSX.Element }) =>
    providers.reduceRight((acc, Provider) => <Provider>{acc}</Provider>, children);
