import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Alert, AlertTitle, Box } from '@mui/material';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

export const ErrorBoundary = () => {
  const error = useRouteError();

  useEffect(() => {
    // notify maintaince team. Simple email with stack trace should be enough
    console.error(error);
  }, [error]);

  return (
    <Box>
      <Alert color="error" icon={<ErrorOutlineOutlinedIcon />}>
        <AlertTitle>Error</AlertTitle>
        Something went wrong.
      </Alert>
    </Box>
  );
};
