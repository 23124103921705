import { CssBaseline, ThemeOptions, ThemeProvider, buttonClasses, createTheme } from '@mui/material';
import createPalette from '@mui/material/styles/createPalette';
import createTypography from '@mui/material/styles/createTypography';
import createSpacing from '@mui/system/createTheme/createSpacing';
import { PropsWithChildren } from 'react';

const altheraColors = {
  red: {
    main: '#EF4929'
  },
  orange: {
    main: '#F68D2C',
    light: '#FFF9F4'
  },
  green: {
    main: '#61AB5B'
  },
  lilac: {
    dark: '#200E6B',
    main: '#5D6792'
  },
  grey: {
    soft: '#D9D9D9',
    medium: '#B0AFAF'
  },
  common: {
    white: '#FFFFFF'
  }
};

const basePalette = createPalette({
  primary: {
    main: altheraColors.lilac.main
  },
  secondary: {
    main: altheraColors.orange.main
  },
  error: {
    main: altheraColors.red.main
  },
  warning: {
    main: altheraColors.orange.main,
    contrastText: altheraColors.common.white
  },
  info: {
    main: altheraColors.lilac.main
  },
  success: {
    main: altheraColors.green.main
  },
  grey: {
    50: altheraColors.grey.soft,
    500: altheraColors.grey.medium
  },
  background: {
    default: altheraColors.common.white,
    defaultDark: altheraColors.lilac.main,
    paper: altheraColors.common.white,
    row: altheraColors.orange.light
  },
  text: {
    primary: altheraColors.lilac.dark,
    secondary: altheraColors.orange.main
  }
});

const baseTypography = createTypography(basePalette, {
  fontFamily: 'Montserrat',
  fontSize: 16,
  titleBig: {
    fontSize: '64px',
    fontWeight: 600,
    lineHeight: 1.1,
    textTransform: 'uppercase'
  },
  titleMedium: {
    fontSize: '40px',
    fontWeight: 600,
    lineHeight: 1.1,
    textTransform: 'uppercase'
  },
  titleSmall: {
    fontSize: '32px',
    fontWeight: 300,
    lineHeight: 1.2,
    textTransform: 'uppercase'
  },
  labelMedium: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: 1.2
  },
  labelSmall: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: 1.2
  },
  subheadingBig: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: 1.3
  },
  subheadingMedium: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.3
  },
  subheadingSmall: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.3
  },
  body1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.3
  },
  paragraphBig: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.3
  },
  paragraphMedium: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.3
  },
  paragraphSmall: {
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: 1.3
  },
  cta: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1.3
  },
  linkBig: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 1
  },
  linkSmall: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: 1,
    textDecoration: 'underline'
  },
  h1: false,
  h2: false,
  h3: false,
  h4: false,
  h5: false,
  h6: false,
  overline: false,
  caption: false
});

const baseSpacing = createSpacing(8);

const gutter: ThemeOptions['gutter'] = {
  xxs: baseSpacing(0.5), // 4px
  xs: baseSpacing(1), // 8px
  sm: baseSpacing(2), // 16px
  md: baseSpacing(3), // 24px
  lg: baseSpacing(4), // 32px
  xl: baseSpacing(6), // 48px
  xxl: baseSpacing(8) // 64px
};

const theme = createTheme({
  palette: basePalette,
  typography: baseTypography,
  spacing: baseSpacing,
  gutter: gutter,
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 880,
      desktop: 1160
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: theme => ({
        '*': {
          position: 'relative',
          minWidth: 0
        },
        html: {
          height: '100%',
          minWidth: theme.breakpoints.values.mobile
        },
        body: {
          height: '100%',
          fontFamily: theme.typography.fontFamily,
          ...theme.typography.body1,
          hyphens: 'auto',

          '& #root': {
            height: '100%'
          }
        },
        strong: {
          fontWeight: theme.typography.fontWeightMedium
        },
        'h1, h2, h3, h4, h5, h6': {
          textWrap: 'balance'
        }
      })
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true
      },
      styleOverrides: {
        root: () => ({
          borderRadius: 0,
          textTransform: 'none'
        })
      },
      variants: [
        {
          props: { color: 'secondary', variant: 'outlined' },
          style: ({ theme }) => ({
            color: theme.palette.common.white,
            borderColor: theme.palette.common.white
          })
        },
        {
          props: { variant: 'text' },
          style: ({ theme }) => ({
            [theme.containerQueries.down(theme.breakpoints.values.tablet)]: {
              [`& .${buttonClasses.endIcon}`]: {
                visibility: 'hidden'
              },
              textDecoration: 'underline'
            }
          })
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: ({ theme }) => ({
            color: theme.palette.primary.contrastText,
            [`& .${buttonClasses.endIcon}`]: {
              '& path': {
                fill: theme.palette.primary.contrastText
              }
            }
          })
        },
        {
          props: { variant: 'text', color: 'inherit' },
          style: ({ theme }) => ({
            color: theme.palette.primary.main,
            [`& .${buttonClasses.endIcon}`]: {
              '& path': {
                fill: theme.palette.primary.main
              }
            }
          })
        }
      ]
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true
      },
      styleOverrides: {
        root: ({ theme }) => ({
          width: 'auto',
          margin: `0 ${theme.spacing(23)}`,
          [theme.containerQueries.down(theme.breakpoints.values.desktop)]: {
            margin: `0 ${theme.spacing(10)}`
          },
          [theme.containerQueries.down(theme.breakpoints.values.tablet)]: {
            margin: `0 ${theme.gutter.sm}`
          }
        })
      }
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
        disableAnimation: true
      },
      variants: [
        {
          props: { shrink: true },
          style: ({ theme }) => ({
            position: 'relative',
            transform: 'translate(0, 0) scale(1)',
            color: theme.palette.primary.main
          })
        }
      ]
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&&': {
            borderRadius: 0
          },
          border: `1.5px solid ${theme.palette.primary.main}`,
          padding: theme.spacing(theme.gutter.xs, theme.gutter.md),
          backgroundColor: theme.palette.common.white
        }),
        input: ({ theme }) => ({
          '&::placeholder': {
            color: theme.palette.grey[500],
            opacity: 1,
            ...theme.typography.paragraphSmall
          }
        })
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        color: 'primary',
        margin: 'none'
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.error.main,
          position: 'relative',
          transform: `translate(-${theme.spacing(theme.gutter.sm)}, -${theme.spacing(theme.gutter.xs)}) scale(1)`
        })
      }
    },
    MuiTypography: {
      defaultProps: {
        variant: 'paragraphMedium',
        variantMapping: {
          titleBig: 'h1',
          titleMedium: 'h1',
          titleSmall: 'h3',
          subheadingBig: 'h4',
          subheadingMedium: 'h5',
          subheadingSmall: 'h6',
          body1: 'p',
          paragraphBig: 'p',
          paragraphMedium: 'p',
          paragraphSmall: 'p',
          cta: 'span',
          linkBig: 'div',
          linkSmall: 'div'
        }
      },
      variants: [
        {
          props: { variant: 'titleBig', gutterBottom: true },
          style: ({ theme }) => ({
            marginBottom: theme.gutter.md
          })
        },
        {
          props: { variant: 'titleMedium', gutterBottom: true },
          style: ({ theme }) => ({
            marginBottom: theme.gutter.md
          })
        }
      ],
      styleOverrides: {
        root: ({ theme }) => ({
          '&.strong, & strong': {
            fontWeight: theme.typography.fontWeightMedium
          }
        })
      }
    }
  }
});

export const ThemeContextProvider = (props: PropsWithChildren) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
};
