import { tss } from 'tss-react/mui';

export const useStyles = tss.withName('LayoutFooter').create(({ theme }) => ({
  root: {
    backgroundColor: theme.palette.background.defaultDark
  },
  appbar: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: '1fr',
    gridTemplateAreas: `"logo menus" "copyright copyright"`,
    rowGap: theme.gutter.lg,
    border: 'none',

    [theme.containerQueries.down(theme.breakpoints.values.desktop)]: {
      rowGap: 0,
      gridTemplateAreas: `"logo menus" "empty copyright"`
    },

    [theme.containerQueries.down(theme.breakpoints.values.tablet)]: {
      gridTemplateAreas: `"logo" "menus" "copyright"`,
      gridTemplateColumns: '1fr'
    }
  },
  logo: {
    gridArea: 'logo',
    '& img': {
      [theme.containerQueries.down(theme.breakpoints.values.tablet)]: {
        height: theme.typography.pxToRem(14)
      }
    }
  },
  menus: {
    gridArea: 'menus',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(0, 0, 0, theme.gutter.md),
    [theme.containerQueries.down(theme.breakpoints.values.desktop)]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: theme.gutter.lg,
      gridTemplateRows: 'minmax(100px, 1fr) auto',
      padding: `0 0 ${theme.gutter.xl} ${theme.gutter.sm}`
    },
    [theme.containerQueries.down(theme.breakpoints.values.tablet)]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: 0,
      gridTemplateRows: '1fr 1fr',
      padding: 0,
      placeItems: 'center'
    }
  },
  menu: {
    flex: 1,
    padding: 0,
    [theme.containerQueries.down(theme.breakpoints.values.tablet)]: {
      '&:nth-of-type(3n)': {
        justifySelf: 'flex-end'
      },
      '&:nth-of-type(3n + 1)': {
        justifySelf: 'flex-start'
      }
    }
  },
  copyright: {
    gridArea: 'copyright',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.gutter.md,
    [theme.containerQueries.down(theme.breakpoints.values.desktop)]: {
      justifyContent: 'flex-start'
    },
    [theme.containerQueries.down(theme.breakpoints.values.tablet)]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `"terms policy" "link link"`,
      gap: theme.gutter.md,
      padding: theme.gutter.md + ' 0',
      placeItems: 'center'
    }
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.common.white,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  copyrightText: {
    gridArea: 'link',
    [theme.containerQueries.down(theme.breakpoints.values.desktop)]: {
      paddingLeft: theme.gutter.sm,
      marginLeft: theme.gutter.sm
    }
  },
  copyrightPolicy: {
    gridArea: 'policy',
    justifySelf: 'flex-end'
  },
  copyrightTerms: {
    gridArea: 'terms',
    justifySelf: 'flex-start'
  },
  mainMenuItem: {
    whiteSpace: 'nowrap',
    [theme.containerQueries.down(theme.breakpoints.values.tablet)]: {
      padding: 0
    }
  },
  subMenuItem: {}
}));
