import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useMediaHooks } from '@althera/website/hooks/useMediaHooks';
import { useStyles } from './index.style';

export const NotFound = () => {
  const { classes } = useStyles();
  const { isMobile } = useMediaHooks();
  return (
    <Box className={classes.root}>
      <Typography variant="titleBig" textAlign="center">
        404
      </Typography>
      <Typography variant="titleMedium" textTransform={isMobile ? 'uppercase' : 'none'} fontWeight={isMobile ? 'fontWeightLight' : 'fontWeightMedium'} textAlign="center">
        We couldn't find the <strong>page</strong>
      </Typography>
      <Typography className={classes.text}>
        It looks like the link you followed may be broken, or the page you’re looking for may have been removed.
        <br />
        Please check the URL again or go back to the&nbsp;
        <Link to="/">homepage</Link>.
      </Typography>
    </Box>
  );
};
