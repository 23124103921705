import { tss } from 'tss-react/mui';

export const useStyles = tss.withName('LayoutHeader').create(({ theme }) => ({
  appbar: {
    border: 'none',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.containerQueries.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'row-reverse'
    }
  }
}));
