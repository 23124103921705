import { Menu, MenuItem, Typography } from '@mui/material';
import { Fragment, useRef } from 'react';
import { Link } from 'react-router-dom';
import { MenuButton } from '@althera/website/layout/header/components/navMenu/blocks/menuButton';
import { NavMenuItemProps } from '@althera/website/layout/header/types';
import { useStyles } from './index.style';

export const NavMenuItem = (props: NavMenuItemProps) => {
  const { to, label, subLinks, pathname, subMenuOpen, onMenuOpen, hash } = props;
  const { classes, cx, theme } = useStyles();
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <Fragment key={to}>
      <MenuButton anchorEl={ref} isMenuOpen={subMenuOpen === label} onMenuOpen={() => onMenuOpen(label)} to={to} label={label} pathname={pathname} hasSubLinks={!!subLinks} />
      {subLinks && (
        <Menu
          disablePortal
          classes={{ paper: classes.menuPaper }}
          open={subMenuOpen === label}
          anchorEl={ref.current}
          onClose={() => onMenuOpen('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          {subLinks.map(({ to: subLinkTo, label: subLinkLabel }) => (
            <MenuItem
              disableGutters
              component={Link}
              key={subLinkTo}
              onClick={() => onMenuOpen('')}
              to={`${to}#${subLinkTo}`}
              className={cx(classes.menuItem, { [classes.subMenuItemActive]: `#${subLinkTo}` === hash })}>
              <Typography className={cx(classes.menuItemText)} variant="subheadingSmall" fontFamily="Montserrat" fontWeight={theme.typography.fontWeightLight}>
                {subLinkLabel}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Fragment>
  );
};
