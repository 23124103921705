import { MenuLink } from '@althera/website/layout/types';

export const links: MenuLink[] = [
  { to: '/', label: 'Home' },
  {
    label: 'About us',
    to: '/about-us',
    subLinks: [
      { to: 'about-althera', label: 'About Althera' },
      { to: 'key-facts', label: 'Key Facts' },
      { to: 'history-milestones', label: 'History & Milestones' },
      { to: 'facilities', label: 'Facilities' }
    ]
  },
  {
    label: 'Culture',
    to: '/culture',
    subLinks: [
      { to: 'our-mission', label: 'Our Mission' },
      { to: 'althera-values', label: 'Althera Values' },
      { to: 'our-sustainability-commitment', label: 'Our Susteinability Commitment' }
    ]
  },
  {
    label: 'Team',
    to: '/team',
    subLinks: [
      { to: 'global-management-team', label: 'Global Management Team' },
      { to: 'leadership-team', label: 'Leadership Team' },
      { to: 'clinical-and-regulatory-expertise', label: 'Clinical and Regulatory Expertise' },
      { to: 'our-supply-chain-excellence', label: 'Our Supply Chain Excellence' }
    ]
  },
  { to: '/products', label: 'Products' },
  { to: '/careers', label: 'Careers' },
  { to: '/contact-us', label: 'Contact Us' }
];
