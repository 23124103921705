import { useCallback, useState } from 'react';

export const useSubMenuOpen = () => {
  const [subMenuOpen, setSubMenuOpen] = useState<Set<string>>(new Set());
  const handleSubMenuOpen = useCallback(
    (to: string) =>
      setSubMenuOpen(prev => {
        if (prev.has(to)) {
          const newSet = new Set(prev);
          newSet.delete(to);
          return newSet;
        }
        return new Set([...prev, to]);
      }),
    []
  );
  return { subMenuOpen, handleSubMenuOpen };
};
