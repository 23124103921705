import { tss } from 'tss-react/mui';

export const useStyles = tss.withName('Layout').create(() => ({
  root: {
    height: '100%',
    containerName: 'layout',
    containerType: 'inline-size',
    overflow: 'auto'
  }
}));
